import { Component, OnInit } from '@angular/core';
import { createCalendar } from '../services/calendar';
import { CalendarService } from '../services/calendar.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NgForm, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { GoogleAnalyticsService } from "../google-analytics.service"; // import our analytics service
import '../../assets/smtp.js'; //file path may change → 





@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.css']
})
export class MeetingComponent {
  declare Email: any;
  profileForm: FormGroup;
  constructor(
    private calendarService: CalendarService,
    private _fb: FormBuilder,
    public googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.profileForm = this._fb.group({
      subject: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      body: ['', [Validators.required]],
      date: ['', [Validators.required]],
      start: ['', [Validators.required]],
      end: ['', [Validators.required]]
    });
  }

  SendLikeEvent() {
    //We call the event emmiter function from our service and pass in the details
    this.googleAnalyticsService.eventEmitter("userPage", "like", "userLabel", 1);
  }


  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.profileForm.value);
  }

  sendEmail() {
      this.Email.send({
      Host : 'email-ssl.com.br',
      Username : 'ti@cadmus.com.br',
      Password : 'Cad@1725',
      To : 'cti@cadmus.com.br',
      From : 'ti@cadmus.com.br',
      Subject : 'Meeting PPPRO',
      Body : `
      <i>This is sent as a feedback from my resume page.</i> <br/> <b>Name: </b> <br /> <b>Email: </b><br /> <b>Subject: </b><br /> <b>Message:</b> <br />  <br><br> <b>~End of Message.~</b> `
      }).then( message => {alert(message);} );
    }
  

  private newMethod() {
    let Email: any;
    return Email;
  }

  AddEvent() {
    /* console.log(JSON.stringify(this.profileForm.getRawValue())) */
    const startdate = this.profileForm.get("date").value + "T" + this.profileForm.get("start").value + ":00.000Z";
    const enddate = this.profileForm.get("date").value + "T" + this.profileForm.get("end").value + ":00.000Z"

    let objeto = new createCalendar();
    objeto.subject = this.profileForm.get("subject").value;
    objeto.startDateTime = startdate;
    objeto.endDateTime = enddate;
    console.log(JSON.stringify(objeto))
    this.calendarService.addCalendar(objeto).then((data) => {
      Swal.fire({
        showLoaderOnConfirm: true,
        title: '<strong>Reunião criada com Sucesso!!</strong>',
        icon: 'info',
        html:
          'Acesse esse  ' +
          '<a href="' + data.joinUrl + '">link</a> ' +
          'ou ainda copie e envie por email',
        showCloseButton: true,

        confirmButtonText: 'Copiar Link!',
        preConfirm: () => {
          let selBox = document.createElement('textarea');
          selBox.style.position = 'fixed';
          selBox.style.left = '0';
          selBox.style.top = '0';
          selBox.style.opacity = '0';
          selBox.value = data.joinUrl;
          document.body.appendChild(selBox);
          selBox.focus();
          selBox.select();
          document.execCommand('copy');
          document.body.removeChild(selBox);

          Swal.fire('Sucesso!',
            'Link foi compiado para sua área de transferencia.',
            'success')
        }

      })
    });
  }


}
